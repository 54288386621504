import localStorage from 'connections/localStorage'

export const setPlace = (place) => {
  place
    ? localStorage.setItem('placeId', place.id)
    : localStorage.removeItem('placeId')
  return { place }
}

export const updateMe = (me) => (state) => {
  const nme = { ...state.me || {}, ...me }
  return { me: nme }
}

export const setMe = (me) => ({ me })

// does both actions at once, so it happens in one subscribe event
export const login = (me, place) => ({
  ...place ? setPlace(place) : {},
  ...setMe(me)
})
