import { PureComponent } from 'react'
import PropTypes from 'meta/PropTypes'
import cn from 'classnames'
import { Box, Layout } from 'react-flex-lite'
import './index.scss'

@Layout
export default class Truncate extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.node
  }
  render = () => {
    const { className, children, ...rest } = this.props
    const ourClass = cn('truncate-component', className)
    return <Box className={ourClass} title={typeof children === 'string' ? children : undefined} {...rest}>
      {children}
    </Box>
  }
}
