/* eslint react/prop-types: 0 */
import store from 'meta/store'
import { passOn, get as getRedirectTo } from 'lib/redirectTo'
import { Redirect } from 'react-router-dom'
import isRedirect from 'routes/isRedirect'

export const compose = (...fns) => (...args) =>
  fns.reduce((result, fn) => {
    if (!result) return result
    if (isRedirect(result)) return result // redirect pending
    return fn(...args)
  }, true)

/*
  Notes for all of these functions:

  Acceptable return values: a redirect, undefined, or true
  If undefined: Did not pass, but I am not doing anything about this (will not continue on if used with compose)
  If true: I checked it and it passed (will continue on if used with compose)
  If redirect: Here is a redirect (will not continue on if used with compose)
*/

// logic happens in this order:
// if not logged in, redirect to /
// if logged in and not verified:
//   if email defined redirect to /confirmation
// if place and !me.onboarding.welcomed redirect to /hello
export const checkLoggedIn = function (props) {
  const { location } = props
  const { me } = store.getState()

  // not logged in at all
  if (!me) {
    if (location.pathname === '/login') return
    return <Redirect to={passOn('/login', { local: true })} />
  }
  return checkAccountState(props)
}


export const checkAccountState = function () {
  const { me } = store.getState()
  if (!me) return true
  if (!me.verified) {
    // logged in but not verified, redirect to confirmation screen
    if (location.pathname === '/confirmation') return
    return <Redirect to={passOn('/confirmation', { local: true })} />
  }
  return true
}

export const checkLoggedOut = function () {
  const { me } = store.getState()
  if (!me) return true
  return <Redirect to="/" />
}

// if user is verified, redirect to /
export const checkUnverified = function () {
  const { me } = store.getState()
  if (!me.verified) return true
  return <Redirect to={getRedirectTo() || '/'} />
}
