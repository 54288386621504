import { PureComponent } from 'react'
import PropTypes from 'meta/PropTypes'
import Empty from 'components/display/Empty'
import Page from 'components/layout/Page'
import Header from 'components/navigation/Header'

export default class NotFound extends PureComponent {
  static propTypes = {
    history: PropTypes.history.isRequired
  }
  handleBack = () => {
    if (this.props.history.length > 2) {
      return this.props.history.goBack()
    }
    this.props.history.push('/')
  }
  render = () =>
    <Page className="not-found-component" title={{ plain: true, title: 'Not found', statusCode: 404 }}>
      <Header breadcrumb={{ text: 'Back', onClick: this.handleBack }} />
      <Empty title="Sorry, that page doesn't exist!" />
    </Page>

}
