import * as Sentry from '@sentry/browser'
import config from 'meta/config'
import store from 'meta/store'

const enabled = config.sentry?.dsn
if (enabled) Sentry.init(config.sentry)

const parseUser = (user) => ({
  id: user.id,
  username: user.authId,
  name: user.name,
  email: user.email,
  createdAt: user.createdAt,
  avatar: user.image,
  authMethod: user.authMethod
})

export default (e, extra) => {
  console.error(e.stack || e)
  if (!enabled) return
  const storeState = store?.getState()
  Sentry.withScope((scope) => {
    const { me, place } = storeState || {}
    if (me) scope.setUser(parseUser(me))
    if (place) scope.setTag('placeId', place.id)
    if (extra) {
      Object.entries(extra).forEach(([ k, v ]) => scope.setExtra(k, v))
    }
    Sentry.captureException(e)
  })
}
