import { PureComponent } from 'react'
import { toast, ToastContainer as Container, cssTransition } from 'react-toastify'
import cn from 'classnames'
import './index.scss'

const SlideAndFade = cssTransition({
  enter: 'slideAndFadeIn',
  exit: 'slideAndFadeOut',
  duration: 750
})

const defaultProps = {
  ...Container.defaultProps,
  autoClose: 3000,
  closeButton: false,
  hideProgressBar: true,
  draggable: false,
  closeOnClick: false,
  className: 'toast-component',
  position: 'bottom-left',
  type: 'default',
  transition: SlideAndFade
}

export const notify = (text, opts = {}) => {
  const props = {
    ...defaultProps,
    ...opts,
    className: `${opts.className || defaultProps.className} toast-type-${opts.type || defaultProps.type}`
  }
  return toast(text, props)
}

export class ToastContainer extends PureComponent {
  static propTypes = Container.propTypes
  static defaultProps = defaultProps
  render = () => {
    const {
      className,
      bodyClassName,
      progressClassName,
      position,
      ...rest
    } = this.props

    const rootClass = 'toast-container'
    return <Container
      className={cn(rootClass, `${rootClass}-${position}`, bodyClassName)}
      bodyClassName={cn('toast-body', bodyClassName)}
      progressClassName={cn('toast-progress', progressClassName)}
      position={position}
      {...rest} />
  }
}
