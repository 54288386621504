/* eslint-disable react/no-unused-prop-types */
import { createElement, Component } from 'react'
import PropTypes from 'meta/PropTypes'
import cn from 'classnames'
import { getPropsWithLayout, propTypes } from 'react-flex-lite'
import './index.scss'

const Text = (displayName, defaultProps = {}) =>
  class TextComponent extends Component {
    static displayName = displayName
    static propTypes = {
      className: PropTypes.string,
      as: PropTypes.string,
      children: PropTypes.node,
      truncate: PropTypes.bool,
      uppercase: PropTypes.bool,
      capitalize: PropTypes.bool,
      weight: PropTypes.oneOf([ 'bold', 'semibold', 'medium', 'normal' ]),
      ...propTypes
    }
    render = () => {
      const fullProps = { ...defaultProps, ...this.props }
      const { className, children, as, truncate, uppercase, capitalize, weight, ...rest } = fullProps
      const merged = cn('text-component', className, defaultProps.className, weight, { truncate, uppercase, capitalize })
      return createElement(as || 'div', getPropsWithLayout({ className: merged, ...rest }), children)
    }
  }

export default Text
