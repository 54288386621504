import 'lib/polyfills'
import 'connections/sentry'
import 'connections/analytics'
import { hot } from 'react-hot-loader/root'
import DOM from 'react-dom'
import ready from 'domready'
import Modal from 'react-modal'
import Root from 'views/core/Root'
import { Provider } from 'meta/context'
import { BrowserRouter as Router } from 'react-router-dom'

const BasicRoot = hot(() =>
  <Provider>
    <Router>
      <Root />
    </Router>
  </Provider>
)
const render = () => {
  const el = document.querySelector('#app-root')
  Modal.setAppElement(el)
  DOM.render(<BasicRoot />, el)
}

// go
ready(render)

export default BasicRoot
