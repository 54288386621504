import { PureComponent } from 'react'
import { Box, Flex, Layout } from 'react-flex-lite'
import context from 'meta/context'
import PropTypes from 'meta/PropTypes'
import PageClick from 'react-page-click'
import whiteLogo from 'assets/logo-white.svg'
import darkLogo from 'assets/logo-black.svg'
import { MdMoreVert } from 'components/display/Icons'
import ErrorMessage from 'components/display/Error'
import LinkButton from 'components/interactive/LinkButton'
import { getColors, isHub } from 'lib/hub'
import cloudinary from 'connections/cloudinary'
import tcolor from 'tinycolor2'
import './index.scss'

const getLogoImage = (place) =>
  cloudinary.url(place.theme.logoImage, { height: 64, crop: 'fit' })

const linkList = PropTypes.arrayOf(PropTypes.shape({
  visible: PropTypes.oneOfType([
    PropTypes.func, // test to show or not
    PropTypes.bool
  ]),
  title: PropTypes.string, // link title
  text: PropTypes.string,
  Icon: PropTypes.func, // icon function
  to: PropTypes.string, // url to go to
  external: PropTypes.bool, // is this a SPA route or an external page?
  onClick: PropTypes.func // additional stuff to run on click
}))

const FlexA = Layout((props) => <a {...props} />)
const TopBarLink = (link) =>
  <LinkButton
    plain center
    external={link.external}
    to={link.to}
    onClick={link.onClick}
    title={link.title}
    Icon={link.Icon}
    exact={link.exact || false}
    className="topbar-link">
    {link.text ? <div className="link-text">{link.text}</div> : null}
  </LinkButton>

@context([ 'me', 'place' ])
export default class TopBar extends PureComponent {
  static propTypes = {
    me: PropTypes.user,
    place: PropTypes.place,
    logo: PropTypes.bool,
    leftLinks: linkList,
    rightLinks: linkList,
    children: PropTypes.node
  }
  state = {
    error: null,
    showMenu: false
  }
  componentDidCatch = (error, errorInfo) => {
    console.error('TopBar caught error!', error, errorInfo)
    this.setState({ error, errorInfo })
  }
  filterLink = (l) => typeof l.visible === 'function'
    ? l.visible(this.props.me, this.props.place)
    : l.visible !== false
  closeMenu = () => {
    this.setState({ showMenu: false })
  }
  toggleMenu = () => {
    this.setState((s) => ({ showMenu: !s.showMenu }))
  }
  overClick = (link) => {
    const { onClick } = link
    link.onClick = (e) => {
      this.closeMenu()
      if (onClick) return onClick(e)
    }
    return link
  }
  renderMenu = () => {
    if (!this.state.showMenu) return null
    return <Flex className="topbar-menu">
      {this.renderLinks(this.props.rightLinks.map(this.overClick))}
    </Flex>
  }
  renderBottomLinks = () => {
    if (this.props.rightLinks.length === 0) return null
    if (this.props.rightLinks.filter(this.filterLink).length === 1) {
      return <Flex className="bottom-links">
        {this.renderLinks(this.props.rightLinks)}
      </Flex>
    }
    return <PageClick notify={this.closeMenu}>
      <Flex>
        {this.renderMenu()}
        <TopBarLink
          Icon={MdMoreVert}
          onClick={this.toggleMenu} />
      </Flex>
    </PageClick>
  }
  getNav = () => {
    const { displayName } = this.props.place
    const hubColors = getColors(this.props.place)
    const logoLink = <Flex className="logo-container" center mr={2}>
      <FlexA
        flex center href={isHub() ? '/' : undefined} title="Home"
        px={2} className="topbar-logo">
        {this.props.place.theme.logoImage
          ? <img src={getLogoImage(this.props.place)} />
          : <>
            <img src={tcolor(hubColors.color).isDark() ? darkLogo : whiteLogo} />
            <Box ml={2}>{displayName}</Box>
          </>}
      </FlexA>
    </Flex>

    return <Flex className="topbar-content" style={hubColors}>
      {this.props.logo && logoLink}
      <Flex auto justify="space-between">
        {this.props.leftLinks &&
          <Flex>
            {this.renderLinks(this.props.leftLinks)}
          </Flex>}
        {this.renderBottomLinks()}
      </Flex>
    </Flex>
  }
  renderLinks = (links) => links.filter(this.filterLink).map(this.renderLink)
  renderLink = (link, idx) => {
    // clear the error state to give the next route a chance to render
    const onClick = this.state.error
      ? (...args) => this.setState({ error: null }) || link.onClick && link.onClick(...args)
      : link.onClick
    return <TopBarLink {...link} onClick={onClick} key={idx} />
  }

  render = () =>
    <Flex column className="topbar-component">
      {this.getNav()}
      <div className="topbar-child-content">
        {this.state.error
          ? <Flex auto center style={{ height: '100%' }}>
            <ErrorMessage error={this.state.error} errorInfo={this.state.errorInfo} />
          </Flex>
          : this.props.children
        }
      </div>
    </Flex>
}
