import { createBrowserHistory } from 'history'

const debug = () => console.log('Location changed:', window.location.href)

const pushState = window.history.pushState
window.history.pushState = function () {
  const ret = pushState.apply(window.history, arguments)
  debug()
  return ret
}
document.addEventListener('popstate', debug, false)
debug()

export default createBrowserHistory()
