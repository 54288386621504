import cn from 'classnames'
import { PureComponent } from 'react'
import PropTypes from 'meta/PropTypes'
import { MdSearch, MdClear } from 'components/display/Icons'
import TextInput from 'components/interactive/TextInput'
import './index.scss'

export default class Search extends PureComponent {
  static propTypes = {
    ...TextInput.PropTypes,
    iconColor: PropTypes.string,
    clearable: PropTypes.bool
  }
  static defaultProps = {
    ...TextInput.defaultProps,
    name: 'search',
    placeholder: 'Search',
    title: 'Search',
    w: '16rem'
  }

  handleClear = () => {
    this.props.onChange({ target: { value: '' } })
  }

  render = () => {
    const { className, clearable, iconColor, ...rest } = this.props
    const classnames = cn('search-component', className)
    const isClearable = clearable && !!this.props.value
    const clearIcon = <MdClear
      size={24} className="clear-icon" onClick={this.handleClear} />

    const prefix = <MdSearch size={24} className="search-icon" style={iconColor ? { color: iconColor } : undefined} />
    return <TextInput
      data-testid="search"
      className={classnames}
      prefix={prefix}
      postfix={isClearable && clearIcon}
      {...rest} />
  }
}
