import {
  MdDashboard, MdMap, MdBubbleChart, MdPeople,
  MdAccountCircle, MdExitToApp, MdLock, MdDvr,
  MdExplore, MdInsertChart, MdGroupWork, MdBook
} from 'components/display/Icons'
import { isAdmin } from 'lib/getUserRole'

export const topLinks = [
  {
    title: 'Home',
    text: 'Home',
    Icon: MdDashboard,
    to: '/home',
    visible: (me, place) => me && place
  },
  {
    title: 'Insights',
    text: 'Insights',
    Icon: MdInsertChart,
    to: '/collections',
    visible: (_, place) => place
  },
  {
    title: 'Map',
    text: 'Map',
    Icon: MdMap,
    to: '/map',
    visible: (_, place) => place
  },
  {
    title: 'Explore',
    text: 'Explore',
    Icon: MdExplore,
    to: '/explore',
    visible: (_, place) => !place
  },
  {
    title: 'Data',
    text: 'Data',
    Icon: MdBubbleChart,
    to: '/sources',
    visible: (me) => me
  }
]

export const bottomLinks = [
  {
    title: 'Activity',
    text: 'Activity',
    Icon: MdDvr,
    to: '/activity',
    visible: (me, place) => me && place
  },
  {
    title: 'Team',
    text: 'Team',
    Icon: MdPeople,
    to: '/team',
    visible: (me, place) => me && place
  },
  {
    title: 'Manage Organization',
    text: 'Organization',
    Icon: MdGroupWork,
    to: '/org/manage',
    visible: (me, place) => isAdmin(me) && place
  },
  {
    title: 'Pages',
    text: 'Pages',
    Icon: MdBook,
    to: '/pages',
    visible: (me, place) => me && place
  },
  /*{
    title: 'Usage',
    text: 'Usage',
    Icon: MdMultilineChart,
    to: '/hub/usage',
    visible: (me, place) => isAdmin(me) && place
  },*/
  // stuff everyone can see
  {
    visible: (me) => me,
    title: 'Account',
    text: 'Account',
    Icon: MdAccountCircle,
    to: '/account'
  },
  {
    visible: (me) => !me,
    title: 'Sign In',
    text: 'Sign In',
    Icon: MdLock,
    to: '/login'
  },
  {
    visible: (me) => me,
    title: 'Sign-out',
    text: 'Sign out',
    Icon: MdExitToApp,
    to: '/logout'
  }
]
