import store from 'meta/store'
import config from 'meta/config'
import color from 'tinycolor2'
import getBestFontColor from 'lib/getBestFontColor'
import url from 'url'

export const isHub = () => !!store.getState().place?.isHub
export const getPlace = () => {
  if (!isHub()) return
  return store.getState().place
}

export const getURL = (place, { allowCustom = true } = {}) => {
  if (!place) return
  if (allowCustom && place.domain) return `https://${place.domain}`
  const parsed = url.parse(config.paths.public)
  const segments = [
    place.id,
    ...parsed.host.split('.')
  ]
  return `${parsed.protocol}//${segments.join('.')}`
}

// returns an object to put into a DOM style object
export const getColors = (place = store.getState().place) => {
  const backgroundColor = place?.theme.primaryColor || '#6552f9'
  return {
    backgroundColor,
    color: getBestFontColor(backgroundColor)
  }
}

export const getDarkColors = (place = store.getState().place) => {
  const backgroundColor = color(place?.theme.primaryColor || '#6552f9')
    .desaturate(15)
    .darken(10)
    .toHexString()
  return {
    backgroundColor,
    color: getBestFontColor(backgroundColor)
  }
}
