import config from 'meta/config'
import store from 'meta/store'
import getUserRole from 'lib/getUserRole'
import load from 'analytics.js-loader'

const analytics = config.tracking ? load(config.segment.key) : null

const getProfile = (u) => ({
  id: u.id,
  username: u.authId,
  name: u.name,
  email: u.email,
  createdAt: u.createdAt,
  avatar: u.image,
  authMethod: u.authMethod,
  placeId: u.place && u.place.id,
  placeName: u.place && u.place.name,
  role: getUserRole(u) || 'developer'
})

const identify = (me = store?.getState().me, place = store?.getState().place) => {
  if (!analytics) return
  if (me) {
    // only call alias if they just registered within the last 5 seconds
    if (me.createdAt > Date.now() - 5000) analytics.alias(me.id)
    analytics.identify(me.id, getProfile(me))
  }
  if (place) analytics.group(place.id)
}

// any time the store updates, re-identify in case the user or place changed
store.subscribe(() => identify())

export default {
  page: (...a) => analytics && analytics.page(...a),
  track: (...a) => {
    console.log(...a)
    analytics && analytics.track(...a)
  }
}
