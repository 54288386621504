import {
  MdAccountCircle, MdExitToApp
} from 'components/display/Icons'

export const leftLinks = [
  {
    title: 'Data',
    text: 'Data',
    to: '/types'
  },
  {
    title: 'Insights',
    text: 'Insights',
    to: '/collections',
    visible: (_, place) => place.collections?.length > 0
  },
  {
    title: 'Map',
    text: 'Map',
    to: '/map'
  },
  {
    title: 'API',
    text: 'API',
    to: '/api'
  }
]

export const rightLinks = [
  // stuff everyone can see
  {
    visible: (me) => !me,
    title: 'Sign In',
    text: 'Sign In',
    to: '/login'
  },
  {
    visible: (me) => me,
    title: 'Account',
    text: 'Account',
    Icon: MdAccountCircle,
    to: '/account'
  },
  {
    visible: (me) => me,
    title: 'Sign-out',
    text: 'Sign out',
    Icon: MdExitToApp,
    to: '/logout'
  }
]
