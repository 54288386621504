let localStorage
if (typeof window !== 'undefined') {
  try {
    localStorage = window.localStorage
  } catch (_) {
    // ignore, incognito window
  }
}

// just wraps it to handle localStorage being disabled without blowing up
export default {
  removeItem: (k) => {
    localStorage?.removeItem(k)
  },
  getItem: (k) => localStorage?.getItem(k),
  setItem: (k, v) => {
    try {
      localStorage?.setItem(k, v)
    } catch (err) {
      console.warn(`localStorage failed to set ${k}:`, err)
    }
  }
}
