import { createStore } from 'react-contextual'
import * as actions from './actions'

// This is the primary app store
// The app store is used for login information, user state, etc.
export default createStore({
  place: null,
  me: null,
  boundary: null,

  ...actions
})
