import PropTypes from 'meta/PropTypes'
import cn from 'classnames'
import { Flex, Layout } from 'react-flex-lite'

import { Small } from 'components/display/Text'
import './index.scss'

const errorType = PropTypes.oneOfType([
  PropTypes.bool,
  PropTypes.string,
  PropTypes.validationError,
  PropTypes.shape({ message: PropTypes.string })
])

const ErrorMessage = (error, key) => {
  const msg = error.message || error
  if (!msg || typeof msg === 'boolean') return null
  return <Small key={key} pt={1} as="span" className="error-text" role="alert">{String(msg)}</Small>
}

const idCheck = (props, propName) => {
  if (props.hiddenLabel && (!props[propName] || typeof props[propName] != 'string')) {
    return new Error('id is required')
  }
}

const InputWithLabels = (InputComponent) => {
  const out = (props) => {
    const {
      className, success, error, errors, label,
      title, white, help,
      warning, preSibling, postSibling, id, hiddenLabel, ...rest
    } = props

    const classnames = cn('input-with-labels-component', className, { white, success, error: hasErrors })
    const hasErrors = !!error || errors?.length > 0
    const errorElements = hasErrors && (error
      ? ErrorMessage(error)
      : errors.map(ErrorMessage))

    const inputEl = <InputComponent
      {...rest}
      id={id}
      className="actual-input"
      error={hasErrors || undefined}
      success={success}
      title={title || label}
      aria-describedby={help && id && `${id}-help-text`} />

    return <Flex column className={classnames}>
      {label
        ? <Small as="label" capitalize htmlFor={id} className="label-text" pb={1}>{label}</Small>
        : hiddenLabel && <label htmlFor={id} aria-label={hiddenLabel} />}

      {
        preSibling || postSibling
          ? <Flex align="center" justify="space-between">{preSibling}{inputEl}{postSibling}</Flex>
          : inputEl
      }
      {hasErrors
        ? errorElements
        : warning
          ? <Small pt={1} as="span" className="warning-text" role="status" aria-live="polite">{warning}</Small>
          : help && <Small pt={1} as="span" className="help-text" role={id && 'tooltip'} id={id && `${id}-help-text`}>{help}</Small>}
    </Flex>
  }
  const name = InputComponent.displayName || InputComponent.name
  out.displayName = name ? `InputWithLabels(${name})` : 'InputWithLabels'
  out.propTypes = {
    ...InputComponent.propTypes,

    // siblings of the input element
    preSibling: PropTypes.node,
    postSibling: PropTypes.node,

    success: PropTypes.bool,
    error: errorType,
    errors: PropTypes.arrayOf(errorType),
    label: PropTypes.node,
    hiddenLabel: PropTypes.node,
    id: idCheck,
    help: PropTypes.string,
    warning: PropTypes.string
  }
  return Layout(out)
}
export default InputWithLabels
