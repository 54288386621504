import store from 'meta/store'
import moment from 'moment-timezone'

const getTZ = () =>
  store.getState().place?.timeZone
    || Intl?.DateTimeFormat().resolvedOptions().timeZone

// what is the offset from utc of our desired timezone?
export const getOffset = (d) => {
  const tz = getTZ()
  return moment.tz.zone(tz).utcOffset(moment(d).tz(tz))
}

// given a local date, figure out how much we need to offset it
export const getRelativeOffset = (d) =>
  new Date(d).getTimezoneOffset() - getOffset(d)

export default getTZ
