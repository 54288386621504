import dnt from 'donottrack'

// comes from webpack at build time
const buildState = typeof __BUILD_STATE__ !== 'undefined' ? __BUILD_STATE__ : {}

const { env, key, resources, paths, github, shortener, sentry } = buildState
const isPrerender = typeof navigator !== 'undefined' && navigator.userAgent?.includes('Prerender')
const doNotTrack = typeof navigator === 'undefined' || dnt()

export default {
  key,
  resources,
  paths,
  env,
  github,
  shortener,
  tracking: !doNotTrack && !isPrerender && env !== 'development' && env !== 'testing',
  segment: env === 'production'
    ? { key: 'locked-gAgbOJUPzefsjCHmyjd1PDazw1kuYpL1' }
    : { key: 'ZUPXhl71GDN4JqFoOdHiAcNIMVRJ0hmH' },
  streetview: {
    key: 'AIzaSyB4LMud3gN_i_tyn2_YgFWaaWnNLbWNP8U'
  },
  mapbox: {
    key: 'pk.eyJ1IjoiY29udHJhY29udHJhY29udHJhIiwiYSI6ImNqMHd3b3gwZDAwNmozMm5kbzVqaHp4NnoifQ.n2wEzEJlwi8w8Ljelzi1hQ'
  },
  cloudinary: {
    cloud_name: 'stae',
    secure: true
  },
  sentry: sentry && {
    ...sentry,
    autoBreadcrumbs: {
      xhr: true,
      console: false,
      dom: true,
      location: true,
      sentry: true
    },
    breadcrumbCallback: (crumb) => {
      // exclude segment.io tracking from breadcrumbs
      if (crumb.type === 'http' && crumb.data.url.includes('api.segment.io')) return false
      return crumb
    }
  }
}
