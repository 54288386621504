import { PureComponent } from 'react'
import PropTypes from 'meta/PropTypes'
import cn from 'classnames'
import { NavLink } from 'react-router-dom'
import { Layout } from 'react-flex-lite'
import Truncate from 'components/layout/Truncate'
import { getRelative, isExternalUrl, isSpecialUrl } from 'lib/getRelativeUrl'
import { default as iconify, type as IconType } from 'lib/iconify'
import './index.scss'

export const FlexLink = Layout((props) =>
  <NavLink exact activeClassName="active" {...props} />)
const FlexButton = Layout((props) => <button {...props} />)
const FlexA = Layout((props) => <a {...props} />)

export class BaseLink extends PureComponent {
  static propTypes = {
    samePage: PropTypes.bool,
    external: PropTypes.bool,
    exact: PropTypes.bool,
    to: PropTypes.string
  }
  render = () => {
    const { to, external, samePage, exact, ...rest } = this.props
    const isSpecial = to && isSpecialUrl(to)
    if (isSpecial) return <FlexA href={to} {...rest} />
    const relativeHref = to && getRelative(to)
    const isExternal = relativeHref && external == null ? isExternalUrl(relativeHref) : external
    if (isExternal) {
      return <FlexA
        target={samePage ? undefined : '_blank'}
        href={relativeHref}
        rel="noopener noreferrer"
        {...rest} />
    }

    const useLink = !!to
    const Component = useLink
      ? FlexLink
      : FlexButton

    const nprops = {
      to: relativeHref,
      ...useLink ? { exact } : {}, // only pass `exact` to `<FlexLink>`
      ...rest
    }

    return <Component {...nprops} />
  }
}

@Layout
export default class LinkButton extends PureComponent {
  static propTypes = {
    download: PropTypes.bool,
    samePage: PropTypes.bool,
    external: PropTypes.bool,
    text: PropTypes.string,
    to: PropTypes.string,
    Icon: IconType,
    IconLeft: IconType,
    IconRight: IconType,
    iconSize: PropTypes.number,
    className: PropTypes.string,
    children: PropTypes.node,
    exact: PropTypes.bool,

    disabled: PropTypes.bool,
    alignText: PropTypes.oneOf([ 'left', 'center', 'right' ]),
    active: PropTypes.bool,
    activeClassName: PropTypes.string,
    plain: PropTypes.bool,
    big: PropTypes.bool,
    pill: PropTypes.bool,
    underline: PropTypes.bool,
    padded: PropTypes.bool,

    primary: PropTypes.bool,
    white: PropTypes.bool,
    danger: PropTypes.bool,
    warning: PropTypes.bool,
    success: PropTypes.bool,
    black: PropTypes.bool,
    grey: PropTypes.bool,
    dark: PropTypes.bool
  }
  static defaultProps = {
    exact: true,
    alignText: 'center',
    iconSize: 24
  }

  render = () => {
    const {
      className, disabled, active, IconLeft,
      IconRight, Icon, text, pill, big, children,
      danger, warning, success, white,
      black, grey, dark, primary, plain,
      padded, underline, alignText, iconSize, ...rest
    } = this.props

    const linkButtonChildren = typeof children === 'string'
      ? <Truncate key="children" className="text">{children}</Truncate>
      : children
    const ourClass = cn('link-button-component', {
      active, disabled,
      big, pill, plain, padded, underline,
      danger, primary,
      warning, success, white, black, grey, dark
    }, {
      'has-text': !!text || !!linkButtonChildren,
      'text-left': alignText === 'left',
      'text-center': alignText === 'center',
      'text-right': alignText === 'right'
    }, className)
    const IconLeftComponent = iconify(IconLeft)
    const IconRightComponent = iconify(IconRight)
    const IconComponent = iconify(Icon)
    const content = [
      IconLeft ? <IconLeftComponent size={iconSize} key="icon-left" className="icon left" /> : null,
      text ? <Truncate key="text" className="text">{text}</Truncate> : null,
      Icon ? <IconComponent size={iconSize} key="icon" className="icon" /> : null,
      linkButtonChildren,
      IconRight ? <IconRightComponent size={iconSize} key="icon-right" className="icon right" /> : null
    ]

    return <BaseLink flex align="center" className={ourClass} {...rest}>
      {content}
    </BaseLink>
  }
}
