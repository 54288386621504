import { PureComponent } from 'react'
import PropTypes from 'meta/PropTypes'
import { Box, Layout } from 'react-flex-lite'

import cn from 'classnames'
import CircularProgress from 'components/display/CircularProgress'
import './index.scss'

@Layout
export default class CircularLoader extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    size: PropTypes.number,
    strokeWidth: PropTypes.number
  }
  static defaultProps = {
    size: 48,
    strokeWidth: 4
  }
  render = () => {
    const { className, size, strokeWidth, ...rest } = this.props
    return <Box className={cn('circular-loader-component', className)} {...rest}>
      <CircularProgress size={size} strokeWidth={strokeWidth} />
    </Box>
  }
}
