import { subscribe as baseSubscribe } from 'react-contextual'
import { pick, pickBy } from 'lodash'

export default (map, Provider) => {
  const nmap = Array.isArray(map)
    ? (store = {}, props) => ({ ...pick(store, map), ...props })

    : (store = {}, props) => ({ ...pickBy(map(store)), ...props })

  // needs some extra finesse to hoist propTypes
  const subscribeHOC = baseSubscribe(Provider, nmap)
  return (Component) => {
    const res = subscribeHOC(Component)
    if (Component.displayName) res.displayName = `Subscribe(${Component.displayName})`
    return res
  }
}
