import { PureComponent } from 'react'
import PropTypes from 'meta/PropTypes'
import cn from 'classnames'
import { Layout } from 'react-flex-lite'
import './index.scss'

@Layout
export default class CircularProgress extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    size: PropTypes.number,
    strokeWidth: PropTypes.number
  }
  static defaultProps = {
    size: 24,
    // keep in sync with value in index.scss
    // TODO: convert to CSS variables so we can update in JS & reference in CSS
    strokeWidth: 1.8
  }
  render = () => {
    const { size, className, strokeWidth, ...rest } = this.props
    const radius = size / 2
    const classname = cn('circular-progress', className)

    return <svg
      viewBox={`0 0 ${size} ${size}`}
      width={size}
      height={size}
      preserveAspectRatio="xMidYMid meet" // copying react-icons alignment
      className={classname}
      {...rest}>
      <circle
        className="loader-path"
        cx={radius}
        cy={radius}
        r={radius - strokeWidth / 2}
        fill="none"
        strokeWidth={strokeWidth}
        strokeMiterlimit="20" />
    </svg>
  }
}
