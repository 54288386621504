import Text from 'components/meta/Text'
import './index.scss'

export const Headline = Text('Headline', {
  as: 'h1',
  className: 'headline-text'
})

export const Title = Text('Title', {
  as: 'h2',
  className: 'title-text'
})

export const Subtitle = Text('Subtitle', {
  as: 'h3',
  className: 'subtitle-text'
})

export const Paragraph = Text('Paragraph', {
  as: 'p',
  className: 'paragraph-text'
})

export const Medium = Text('Medium', {
  className: 'medium-text'
})

export const Small = Text('Small', {
  className: 'small-text'
})
