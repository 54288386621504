import qs from 'qs'
import deepEqual from 'react-fast-compare'

const parseConfig = {
  strictNullHandling: true,
  plainObjects: true,
  parameterLimit: 10000,
  arrayLimit: 10000,
  depth: 10000
}

const change = (fn, location, changes) => {
  const existing = qs.parse(location.search, { ignoreQueryPrefix: true, ...parseConfig })
  const nu = { ...existing, ...changes }
  if (deepEqual(nu, existing)) return
  const newSearch = qs.stringify(nu, { strictNullHandling: true })
  fn({ search: `?${newSearch}` })
}

export const set = ({ history, location = window.location } = {}, changes) => {
  if (!history) throw new Error('Missing react-router history object!')
  change(history.push, location, changes)
}

export const replace = ({ history, location = window.location } = {}, changes) => {
  if (!history) throw new Error('Missing react-router history object!')
  change(history.replace, location, changes)
}

export const get = ({ location = window.location } = {}) =>
  qs.parse(location.search, { ignoreQueryPrefix: true, ...parseConfig })
