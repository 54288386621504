import color from 'tinycolor2'

const colors = [
  '#fff',
  '#26252e'
]

// returns the right font color given a background color
// mostReadable will return bad results sometimes, so we order by preference
export default (backgroundColor) =>
  colors.find((i) => color.readability(i, backgroundColor) > 2.1) || '#26252e'
