import { PureComponent } from 'react'
import PropTypes from 'meta/PropTypes'
import { Flex, Layout } from 'react-flex-lite'
import { Title, Paragraph } from 'components/display/Text'
import cn from 'classnames'
import './index.scss'
import primaryImage from 'assets/icons/simplicity.svg'

@Layout
export default class Empty extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    icon: PropTypes.node,
    light: PropTypes.bool,
    compact: PropTypes.bool
  }

  render = () => {
    const { className, children, icon, compact, light } = this.props
    const ourClass = cn('empty-component', className, { light })

    return <Flex
      column auto align="center"
      justify="center" className={ourClass}>
      <Flex column center mb={compact ? 2 : 3} className="empty-image-container">
        {icon || <img src={primaryImage} />}
      </Flex>
      {this.props.title && <Title weight="semibold">{this.props.title}</Title>}
      {this.props.subtitle && <Paragraph className="subtitle" mt={compact ? 1 : 2}>{this.props.subtitle}</Paragraph>}
      {children}
    </Flex>
  }
}
