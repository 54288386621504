import { PureComponent } from 'react'
import { Box } from 'react-flex-lite'
import PageClick from 'react-page-click'
import cn from 'classnames'
import PropTypes from 'meta/PropTypes'
import LinkButton from 'components/interactive/LinkButton'
import { MdHelp } from 'components/display/Icons'
import './index.scss'

class HelpDropdown extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string
  }
  state = {
    active: false
  }
  toggleDropdown = () => {
    this.setState((s) => ({ active: !s.active }))
  }
  hideDropdown = () => {
    this.setState({ active: false })
  }
  render = () =>
    <PageClick notify={this.hideDropdown}>
      <Box
        className={cn('help-dropdown-component', this.props.className)}
        onClick={this.toggleDropdown}>
        <LinkButton plain white active={this.state.active} Icon={MdHelp} className="dropdown-trigger" />
        {this.state.active && <Box className="dropdown-item-container">
          {this.props.children}
          <Item text="Ask for help!" to="https://support.municipal.systems/hc/en-us/requests/new" />
          <Item text="View all docs" to="https://support.municipal.systems/" />
        </Box>}
      </Box>
    </PageClick>
}

class Item extends PureComponent {
  static propTypes = {
    text: PropTypes.string,
    to: PropTypes.string
  }

  render = () =>
    <LinkButton
      plain black className="dropdown-item"
      to={this.props.to} external>
      {this.props.text}
    </LinkButton>
}

HelpDropdown.Item = Item
export default HelpDropdown
