import { get as getQueryParams } from 'lib/qs'
import history from 'connections/history'
import { combineUrl } from 'sutro-client'

export const getLocal = () =>
  getSafeURL(window.location.pathname)

export const get = () =>
  getSafeURL(getQueryParams().redirectTo)

export const passOn = (url, { local } = {}) => {
  // when local is true, use local url for redirectTo if one doesnt exist
  const redirectTo = local
    ? get() || getLocal()
    : get()
  if (!redirectTo) return url
  return combineUrl(url, { redirectTo })
}

export const goTo = (url = '/') => {
  // absolute
  if (url.indexOf('://') !== 0) {
    window.location = url
    return
  }
  // relative
  history.replace(url)
}

export const getSafeURL = (url) => {
  if (!url) return
  // TODO: support full urls, but for now we just use relative so this fn is a no-op
  return url
}
