import { PureComponent } from 'react'
import cn from 'classnames'
import MaskedInput from 'react-maskedinput'
import PropTypes from 'meta/PropTypes'
import { Box, Flex, Layout } from 'react-flex-lite'
import InputWithLabels from 'components/meta/InputWithLabels'
import './index.scss'

const renderable = PropTypes.oneOfType([
  PropTypes.node,
  PropTypes.string
])

@Layout
class TextInput extends PureComponent {
  static propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
    mask: PropTypes.string,
    prefix: renderable,
    postfix: renderable,
    error: PropTypes.bool,
    success: PropTypes.bool,
    readOnly: PropTypes.bool,
    disabled: PropTypes.bool,
    inputRef: PropTypes.func
  }
  state = { focused: false }
  // when the any inner element is clicked, or the main element is clicked
  // focus the input and mark the parent as focused for the highlight
  setInner = (el) => {
    this._inner = el
    if (this.props.inputRef) this.props.inputRef(el)
  }
  focus = () => {
    // don't set focus state for disabled inputs
    if (!this.props.disabled) {
      if (this._inner) this._inner.focus()
      this.setState({ focused: true })
    }
  }
  blur = () => {
    this.setState({ focused: false })
  }
  render = () => {
    const { focused } = this.state
    const {
      className, prefix, postfix, readOnly, inputRef,
      error, style, disabled, success, ...rest
    } = this.props
    const ourClassName = cn('text-input-component', className, {
      focused, error, success,
      disabled, readOnly
    })
    const input = this.props.mask
      ? <MaskedInput
          type="text"
          ref={this.setInner}
          className="input-inner"
          readOnly={readOnly}
          disabled={disabled}
          {...rest} />
      : <input
          type="text"
          ref={this.setInner}
          className="input-inner"
          readOnly={readOnly}
          disabled={disabled}
          {...rest} />

    return <Flex
      center
      onFocus={this.focus} onClick={this.focus} onBlur={this.blur}
      style={style} className={ourClassName}>
      {prefix && <Box mr={1} className="prefix">{prefix}</Box>}
      {input}
      {postfix && <Box ml={1} className="postfix">{postfix}</Box>}
    </Flex>
  }
}

export default InputWithLabels(TextInput)
