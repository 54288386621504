import createClient, { request } from 'sutro-client'
import config from 'meta/config'
import store from 'meta/store'
import { passOn } from 'lib/redirectTo'

const handleAuthError = (err) => {
  if (err.status !== 401) return err
  if (err.response && err.response.status !== 401) return err
  const { me } = store.getState()
  const isLoggedIn = me && me.id
  window.location = isLoggedIn
    ? '/' // logged in but not authorized. redirect to root
    : passOn('/login', { local: true }) // not logged in. log in then retry
}


const opts = {
  rewriteLargeRequests: true,
  // e2e tests share sessions so never cache
  cache: config.env === 'testing' ? 'no-store' : 'default',
  credentials: 'include',
  options: () => ({ key: config.key }),
  root: config.paths.url,
  onError: handleAuthError
}

export default createClient(config.resources, opts)

export const raw = request.create({
  prefixUrl: config.paths.url,
  cache: opts.cache,
  credentials: opts.credentials
})
