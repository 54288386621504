import { PureComponent } from 'react'
import { Layout, Flex, Box } from 'react-flex-lite'
import PropTypes from 'meta/PropTypes'
import { sortBy } from 'lodash'
import context from 'meta/context'
import ClampLines from 'react-clamp-lines'
import cn from 'classnames'
import Search from 'components/interactive/Search'
import { search } from 'fast-fuzzy'
import cloudinary from 'connections/cloudinary'
import { MdSwapHoriz, MdChevronRight } from 'components/display/Icons'
import { Medium } from 'components/display/Text'
import moize from 'moize'
import localStorage from 'connections/localStorage'
import LinkButton from 'components/interactive/LinkButton'
import './index.scss'

const getUserIcon = (user) =>
  user.image && cloudinary.url(user.image, {
    width: 48,
    height: 48,
    crop: 'fill'
  })

const getPlaceIcon = (place) => {
  const img = place.theme?.squareImage || place.theme?.headerImage
  return img && cloudinary.url(place.theme.squareImage || place.theme.headerImage, {
    width: 48,
    height: 48,
    crop: 'fill'
  })
}

const getResults = moize.simple((searchValue, available) => {
  const sorted = sortBy(available, 'name')
  return searchValue
    ? search(searchValue, sorted, { keySelector: (i) => i.name })
    : sorted
})

@context([ 'me', 'place' ])
@Layout
export default class WorkspaceSwitcher extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    me: PropTypes.user,
    place: PropTypes.place,
    active: PropTypes.bool,
    onActivate: PropTypes.func,
    onClose: PropTypes.func
  }
  state = {
    search: ''
  }
  setSearch = (e) => {
    this.setState({ search: e.target.value })
  }
  setPlace = (id) => {
    if (this.props.onClose) this.props.onClose()
    localStorage.setItem('placeId', id)
    window.location.href = '/'
  }
  renderMenu = () => {
    const { me, place } = this.props
    const { search } = this.state
    const userPlaces = me?.places
    if (!userPlaces || userPlaces.length === 0) return null
    const available = place
      ? userPlaces.filter((i) => i.id !== place.id)
      : userPlaces

    const results = getResults(search, available)
    const isSearching = !!search
    const isEmpty = isSearching && results.length === 0
    const onlyOnePlace = !isSearching && results.length === 1
    const actualResults = <Box className={cn('results', { searching: isSearching })}>
      {!this.state.search && place && <LinkButton
        justify="space-between" plain black IconRight={MdSwapHoriz}
        onClick={this.setPlace.bind(this, 'personal-account')}
        title={`Switch to ${me.name}`}
        key="personal-account">Switch to Personal Account</LinkButton>}
      {results.map((i) =>
        <LinkButton
          justify="space-between" plain black
          IconRight={onlyOnePlace ? MdSwapHoriz : MdChevronRight}
          onClick={this.setPlace.bind(this, i.id)}
          title={`Switch to ${i.name}`}
          key={i.id}>{onlyOnePlace ? 'Switch to ' : onlyOnePlace}{i.name}</LinkButton>
      )}
    </Box>
    const empty = <Flex center className="results empty">
      <Medium>No results!</Medium>
    </Flex>
    return <Flex className="workspace-menu">
      {available.length > 5 && <Search m={1} autoFocus clearable onChange={this.setSearch} value={this.state.search} />}
      {isEmpty ? empty : actualResults}
    </Flex>
  }
  render = () => {
    const { me, place, active, className } = this.props
    const userPlaces = me?.places
    if (!userPlaces || userPlaces.length === 0) return null
    const Icon = place
      ? getPlaceIcon(place)
      : getUserIcon(me)
    return <Flex className={cn('workspace-switcher-component', className)}>
      <LinkButton
        className="activation-link"
        plain column center
        onClick={this.props.onActivate}
        title={place ? place.name : me.name}
        Icon={Icon || Box}>
        <ClampLines
          id="link-text"
          buttons={false}
          debounce={10}
          ellipsis="..."
          text={place ? place.displayName : me.name}
          lines={2}
          className="link-text" />
      </LinkButton>
      {active && this.renderMenu()}
    </Flex>
  }
}
