/* eslint react/prop-types: 0 */
import store from 'meta/store'
import { isAdmin, isSuperAdmin } from 'lib/getUserRole'
import { passOn, get as getRedirectTo } from 'lib/redirectTo'
import { get as getQueryParams } from 'lib/qs'
import { Redirect } from 'react-router-dom'
import isRedirect from 'routes/isRedirect'

// iterates through functions and returns first truthy value
export const some = (...fns) => (...args) =>
  fns.reduce((result, fn) => result || fn(...args), undefined)

export const compose = (...fns) => (...args) =>
  fns.reduce((result, fn) => {
    if (!result) return result
    if (isRedirect(result)) return result // redirect pending
    return fn(...args)
  }, true)

/*
  Notes for all of these functions:

  Acceptable return values: a redirect, undefined, or true
  If undefined: Did not pass, but I am not doing anything about this (will not continue on if used with compose)
  If true: I checked it and it passed (will continue on if used with compose)
  If redirect: Here is a redirect (will not continue on if used with compose)
*/

// logic happens in this order:
// if not logged in, redirect to /
// if logged in and not verified:
//   if email defined redirect to /confirmation
// if place and !me.onboarding.welcomed redirect to /hello
export const checkLoggedIn = function (props) {
  const { location } = props
  const { me } = store.getState()

  // not logged in at all
  if (!me) {
    if (location.pathname === '/login') return
    return <Redirect to={passOn('/login', { local: true })} />
  }
  return checkAccountState(props)
}

const redirectToInvite = (inviteId) =>
  <Redirect to={`/invite/${inviteId}`} />

export const checkAccountState = function () {
  const { me, place } = store.getState()
  if (!me) return true
  if (!me.verified) {
    // logged in but not verified, redirect to confirmation screen
    if (location.pathname === '/confirmation') return
    return <Redirect to={passOn('/confirmation', { local: true })} />
  }
  if (place && !(me.onboarding || me.onboarding.welcomed)) {
    if (location.pathname === '/hello') return
    return <Redirect to={passOn('/hello', { local: true })} />
  }
  const { inviteId } = getQueryParams()
  if (inviteId) return redirectToInvite(inviteId)
  return true
}
export const checkLoggedOut = function () {
  const { me } = store.getState()
  if (!me) return true
  const { inviteId } = getQueryParams()
  if (inviteId) return redirectToInvite(inviteId)
  return <Redirect to="/" />
}

// if user is not a place admin, redirect to /login
export const checkPlaceAdmin = function () {
  const { me, place } = store.getState()
  if (isAdmin(me) && place) return true
  return <Redirect to={passOn('/login', { local: true })} />
}

// if user is not a place member, redirect to /login
export const checkPlaceMember = function () {
  const { me, place } = store.getState()
  if (me && place) return true
  return <Redirect to={passOn('/login', { local: true })} />
}

// if user is not a super admin, redirect to /login
export const checkSuperAdmin = function () {
  const { me } = store.getState()
  if (isSuperAdmin(me)) return true
  return <Redirect to={passOn('/login', { local: true })} />
}

// if user is verified, redirect to /
export const checkUnverified = function () {
  const { me } = store.getState()
  if (!me.verified) return true
  return <Redirect to={getRedirectTo() || '/'} />
}

export const checkPlace = function (props) {
  const { place } = store.getState()
  if (place) return checkAccountState(props)
  return <Redirect to="/explore" />
}
