import { Component } from 'react'
import cn from 'classnames'
import { Layout } from 'react-flex-lite'
import LinkButton from 'components/interactive/LinkButton'
import { MdChevronLeft } from 'components/display/Icons'
import './index.scss'

const chevy = (props) => <MdChevronLeft {...props} size={18} />

@Layout
export default class Breadcrumb extends Component {
  static propTypes = LinkButton.propTypes
  render = () => {
    const { className, ...rest } = this.props
    return <LinkButton
      {...rest}
      IconLeft={chevy}
      black title="Previous page"
      className={cn('breadcrumb-component', className)} />
  }
}
