import { PureComponent } from 'react'
import ErrorBase from 'components/display/Error'
import Header from 'components/navigation/Header'
import Page from 'components/layout/Page'

export default class Error extends PureComponent {
  render = () =>
    <Page
      className="error-component"
      title={{ plain: true, title: 'Error', statusCode: 500 }}
      data-testid="error-view">
      <Header breadcrumb={{ text: 'Home', to: '/' }} />
      <ErrorBase retry={false} />
    </Page>
}
