import { PureComponent } from 'react'
import PropTypes from 'meta/PropTypes'
import analytics from 'connections/analytics'
import captureException from 'connections/sentry'
import LinkButton from 'components/interactive/LinkButton'
import { MdRefresh } from 'components/display/Icons'
import { Flex, Layout } from 'react-flex-lite'
import { Title, Paragraph } from 'components/display/Text'
import cn from 'classnames'
import errorImage from 'assets/icons/error.svg'

const reload = () => window.location.reload()
const ensurePromise = (fn) => async (...args) => fn(...args)

const statusMessages = {
  401: {
    title: 'Uh-oh! You don’t have access to this.',
    subtitle: 'Ask your team admin for access, or contact support for help.'
  },
  403: {
    title: 'Uh-oh! You don’t have access to this.',
    subtitle: 'Ask your team admin for access, or contact support for help.'
  },
  404: {
    title: 'Uh-oh! This doesn’t exist.',
    subtitle: 'It may have been deleted, or the URL is invalid.'
  },
  503: {
    title: 'Something took longer than expected.',
    subtitle: 'Try again in a few, or try adjusting your query.'
  },
  default: {
    title: 'Uh-oh! Something went wrong.',
    subtitle: 'Our people are looking into it.'
  }
}

@Layout
export default class ErrorComponent extends PureComponent {
  static propTypes = {
    report: PropTypes.bool,
    errors: PropTypes.arrayOf(PropTypes.error),
    error: PropTypes.error,
    errorInfo: PropTypes.shape({ componentStack: PropTypes.string }),
    statusMessages: PropTypes.object,
    retry: PropTypes.oneOfType([ PropTypes.func, PropTypes.bool ]),
    icon: PropTypes.node,
    light: PropTypes.bool,
    compact: PropTypes.bool,
    className: PropTypes.string
  }
  static defaultProps = {
    retry: reload,
    report: true
  }
  componentDidMount = () => {
    const { error, errors, errorInfo, report } = this.props
    if (!report) return
    if (errors) {
      const [ head, ...tail ] = errors
      captureException(head, { errors: tail })
      analytics.track('Error', head)
    } else if (error) {
      captureException(error, { errorInfo })
      analytics.track('Error', error)
    }
  }
  render = () => {
    const { className, retry, error, icon, compact, light } = this.props
    const messages = { ...statusMessages, ...this.props.statusMessages }
    const status = error?.response?.status || error?.status
    const { title, subtitle } = messages[status] || messages.default
    const ourClass = cn('error-component', className, { error, light })

    return <Flex
      column auto align="center"
      justify="center" className={ourClass}>
      <Flex column center mb={compact ? 2 : 3} className="error-image-container">
        {icon || <img src={errorImage} />}
      </Flex>
      <Title weight="semibold">{title}</Title>
      <Paragraph className="subtitle" mt={compact ? 1 : 2}>{subtitle}</Paragraph>
      {retry && <LinkButton
        my={2} plain white={light}
        text="Try again"
        IconLeft={MdRefresh}
        onClick={ensurePromise(retry)} />}
    </Flex>
  }
}
