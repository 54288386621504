import { PureComponent } from 'react'
import PropTypes from 'meta/PropTypes'
import CircularLoader from 'components/display/CircularLoader'
import { Flex, Layout } from 'react-flex-lite'
import { Medium, Small } from 'components/display/Text'
import cn from 'classnames'
import './index.scss'

@Layout
export default class Loader extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    text: PropTypes.string,
    slow: PropTypes.bool,
    light: PropTypes.bool
  }
  render = () => {
    const { light, className, text, slow, ...rest } = this.props
    const ourClass = cn('loader-component', className, { light })
    return <Flex shrink={0} column center className={ourClass} {...rest}>
      <CircularLoader size={48} />
      {text && <Medium mt={2} weight="medium">{text}</Medium>}
      {slow && <Small mt={1} className="hint">This can take a bit!</Small>}
    </Flex>
  }
}
