import { PureComponent } from 'react'
import { Flex } from 'react-flex-lite'
import ErrorMessage from 'components/display/Error'
import PropTypes from 'meta/PropTypes'

export default class Error extends PureComponent {
  propTypes = {
    error: PropTypes.object
  }
  render = () =>
    <Flex p={2} auto center style={{ height: '100%' }}><ErrorMessage {...this.props} /></Flex>
}
