import PropTypes from 'meta/PropTypes'
import { Layout } from 'react-flex-lite'

// take either a string or function and always return a renderable component
export default (src) => {
  if (typeof src !== 'string') return src // already a component
  const LinkIcon = ({ size, ...props }) => {
    const style = size != null ? {
      ...props.style || {},
      height: size
    } : undefined
    return <img src={src} style={style} {...props} />
  }
  LinkIcon.propTypes = {
    size: PropTypes.number,
    style: PropTypes.object
  }
  LinkIcon.displayName = 'LinkIcon'
  return Layout(LinkIcon)
}

export const type = PropTypes.oneOfType([
  PropTypes.renderable,
  PropTypes.string
])
