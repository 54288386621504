import { PureComponent } from 'react'
import { Flex, Box, Layout } from 'react-flex-lite'
import { Helmet } from 'react-helmet'
import cn from 'classnames'
import MediaQuery from 'react-responsive'
import PropTypes from 'meta/PropTypes'
import context from 'meta/context'
import { desktopWidth } from 'styles/sizes'
import './index.scss'

const sidebarWidth = 80

// Responsible for in-page content layout and responsive padding
@Layout
export class PageContent extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    style: PropTypes.object,
    centeredWidth: PropTypes.oneOfType([
      PropTypes.number, // whatever number specified
      PropTypes.bool // 960px
    ])
  }
  static defaultProps = {
    centeredWidth: true,
    style: {}
  }
  render = () => {
    const { className, centeredWidth, children, style, ...rest } = this.props

    // calculate our desired widths and styles based on props
    const desiredMaxWidth = centeredWidth === true
      ? desktopWidth
      : centeredWidth || null
    const wantsMaxWidth = desiredMaxWidth != null
    const newStyle = wantsMaxWidth && {
      ...style,
      maxWidth: desiredMaxWidth,
      width: '100%',
      margin: '0 auto'
    }

    const getContent = (hitMaxWidth) =>
      <Box
        className={cn('page-content-component', className, { 'below-max-width': hitMaxWidth })}
        style={newStyle || style}
        {...rest}>
        {children}
      </Box>
    return wantsMaxWidth
      ? <MediaQuery maxWidth={desiredMaxWidth + sidebarWidth}>{getContent}</MediaQuery>
      : getContent()
  }
}

@context((s) => ({
  placeName: s.place?.displayName
}))
export class PageTitle extends PureComponent {
  static propTypes = {
    plain: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    placeName: PropTypes.string,
    statusCode: PropTypes.number
  }
  render = () => {
    const { plain, title, placeName, description, statusCode } = this.props
    const pieces = [
      title,
      !plain && placeName
    ].filter((i) => !!i)
    const finalTitle = pieces.join(' · ').trim()
    const desc = description?.trim()
    return <Helmet>
      {finalTitle && <title>{finalTitle}</title>}
      {finalTitle && <meta name="og:title" content={finalTitle} />}
      {desc && <meta name="description" content={desc} />}
      {desc && <meta name="og:description" content={desc} />}
      {statusCode && <meta name="prerender-status-code" content={String(statusCode)} />}
    </Helmet>
  }
}

// Responsible for page title and page container layout (height/width/scroll behavior)
@Layout
export default class Page extends PureComponent {
  static propTypes = {
    fullHeight: PropTypes.bool,
    scrollable: PropTypes.bool,
    title: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape(PageTitle.propTypes)
    ]),
    className: PropTypes.string,
    children: PropTypes.node
  }
  static defaultProps = {
    fullHeight: true,
    scrollable: true
  }
  render = () => {
    const { fullHeight, title, children, className, scrollable, ...rest } = this.props
    const titleProps = typeof title === 'string' ? { title } : title
    return <Flex
      auto column
      className={cn('page-component', className, {
        'full-height': fullHeight,
        scrollable
      })}
      {...rest}>
      {titleProps && <PageTitle {...titleProps} />}
      {children}
    </Flex>
  }
}
