import config from 'meta/config'
import { Cloudinary } from 'cloudinary-core'

const cl = new Cloudinary(config.cloudinary)

const defaults = {
  type: 'fetch',
  secure: true,
  fetchFormat: 'auto',
  quality: 100,
  dpr: 'auto'
}

export default {
  url: (url, options = {}) =>
    cl.url(url, {
      ...defaults,
      ...options
    })
}
